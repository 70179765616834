import PropTypes from 'prop-types'
import styles from './SocialLinks.module.scss'
import { ClassNames } from 'services/classname.service'
import Icon from '../Icon'
import Link from 'basics/Link'

const SocialLinks = ({ direction, color, className }) => (
  <div
    className={ClassNames([styles['social-links'], styles[direction], styles[color], className])}
  >
    <Link href="https://www.linkedin.com/company/firstbusinessbank/" external={true}>
      <Icon icon="linkedin" className="linkedin" label="Visit us on LinkedIn" />
    </Link>
    <Link href="https://facebook.com/firstbusinessbank" external={true}>
      <Icon icon="facebook" className="facebook" label="Visit us on Facebook" />
    </Link>
    <Link href="https://www.instagram.com/firstbusinessbank" external={true}>
      <Icon icon="instagram" className="instagram" label="Visit us on Instagram" />
    </Link>
    <Link href="https://youtube.com/@firstbusinessbank " external={true}>
      <img src="/youtube-icon.png" width="24" height="20" alt="First Business Bank" />
      {/* <Icon icon="youtube" className="youtube" label="Visit us on YouTube" /> */}
    </Link>
    {/* <Link href="/contact-us">
      <Icon icon="envelope" className="envelope" label="Send us an email" />
    </Link> */}
  </div>
)

SocialLinks.propTypes = {
  direction: PropTypes.string,
  color: PropTypes.string
}

SocialLinks.defaultProps = {}

export default SocialLinks
