import styles from './Footer.module.scss'

import GridContainer from 'containers/GridContainer'
import Container from 'containers/Container'
import FlexContainer from 'containers/FlexContainer'
import Button from 'basics/Button'
import Image from 'basics/Image'
import Spacer from 'basics/Spacer'
import SocialLinks from 'basics/SocialLinks'
import Link from 'basics/Link'
import { nanoid } from 'nanoid'
import { ClassNames } from 'services/classname.service'
import { CdnUrl } from 'services/image.service'
import MenuSection from 'components/MenuSection'
import { FooterContent } from 'queries/fragments'
import { PickFirst } from 'services/utils'
import useMarketo from 'hooks/useMarketo'
import MarketoForm from 'components/MarketoForm'

function Footer({
  className = '',
  navLinks = [],
  footerLinks = [],
  footerContent = [],
  route = [],
  type,
  thankYouPage
}) {
  const formId = '3197'
  const { onLoadForm, onRemoveForm, formLoaded, formSuccess } = useMarketo({
    munchkinId: '880-QNO-957',
    formId,
    callback: () => {}
  })

  if (type === 'simple') {
    return (
      <footer className={ClassNames([styles['ds-footer'], styles['ds-footer--simple'], className])}>
        <Container>
          <p>
            <a href="/privacy-notice">PRIVACY NOTICE</a> |{' '}
            <a href="/security-procedures">SECURITY CENTER</a>
          </p>
          <p>
            Copyright @ {new Date().getFullYear()} First Business Financial Services, Inc. All
            rights reserved.
          </p>
        </Container>
      </footer>
    )
  }

  return (
    <>
      <footer className={ClassNames([styles['ds-footer'], 'mt-20 md-mt-13', className])}>
        <Container wide={true}>
          <GridContainer
            gridSize={1}
            gridElementSize="200px auto 140px"
            className="align-items-center"
          >
            <a href="/">
              <img
                loading="lazy"
                src={CdnUrl('logo.svg')}
                width="180"
                height="72"
                className="md-mx-auto display-block md-display-none"
                alt=""
              />
              <span className="sr-only">First Business Bank</span>
            </a>
            <FlexContainer className="align-items-center ml-4 sm-ml-0 sm-mb-2">
              <div className={styles['horizontal-divider']}></div>
              <strong
                className={ClassNames([
                  styles['footer-text'],
                  'ml-3 sm-ml-n  sm-text-align-center'
                ])}
              >
                {footerContent.emailSignup}
              </strong>
            </FlexContainer>
            <div className={styles['ds-footer__signup']}>
              {/* <input
              className="ml-2 mr-4"
              type="text"
              inputMode="email"
              placeholder="Enter Email Address..."
            /> */}

              <Button size="small" onClick={onLoadForm}>
                Sign Me Up
              </Button>
            </div>
            <a href="/">
              <img
                loading="lazy"
                src={CdnUrl('logo.svg')}
                width="180"
                height="72"
                className="md-mx-auto md-mt-8 display-none md-display-block"
                alt=""
              />
              <span className="sr-only">First Business Bank</span>
            </a>
          </GridContainer>

          <Spacer smSize={4} size={10} />

          <GridContainer
            gridSize={5}
            gridColumnGap={115}
            className={ClassNames([styles['footer-content']])}
          >
            <div className={styles['contact']}>
              <p className="mb-4">{footerContent.contactText}</p>

              <Button className="sm-mx-auto sm-display-block" url="/contact-us">
                Contact Us
              </Button>
              <SocialLinks className="mt-3" direction="row" color="light" />
            </div>
            <div className={styles['columns']}>
              <ul className={ClassNames([styles['ul'], 'display-flex'])}>
                {footerLinks?.map((link, idx) => {
                  const page = (link.page && link.page[0]) || {}
                  const title = link.linkTitle || page.name
                  const external = link.alternateLink ? true : null
                  const destination = link.alternateLink || `/${page.url}`
                  return (
                    <li
                      key={nanoid()}
                      className={ClassNames([
                        styles[('li', 'footer-links')],
                        page.url.split('/')[0] === route[0] ? styles['active'] : ''
                      ])}
                    >
                      <Link
                        className={ClassNames([styles['column-heading']])}
                        href={destination}
                        external={external}
                      >
                        {title}
                      </Link>
                      {link.menuSections.map((section) => (
                        <MenuSection data={section} key={nanoid()} footer={true} />
                      ))}
                    </li>
                  )
                })}
                <li className={ClassNames([styles[('li', 'footer-links')]])}>
                  <ul>
                    {footerContent.loginLinks?.map((link, idx) => {
                      const page = (link.page && link.page[0]) || {}
                      const title = link.linkTitle || page.name
                      const external = link.externalLink ? true : null
                      const destination = link.externalLink || `/${page.url}`
                      return (
                        <li
                          key={nanoid()}
                          className={
                            styles[`footer-links__${link.highlighted ? 'highlighted' : 'normal'}`]
                          }
                        >
                          <Link
                            className={ClassNames([styles['small']])}
                            href={destination}
                            external={external}
                          >
                            {title}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </li>
              </ul>
            </div>
          </GridContainer>

          <Spacer smSize={4} size={12} />

          <div className={ClassNames([styles['ds-footer__fineprint'], 'pb-4'])}>
            <div
              className={ClassNames([styles['ds-footer__fineprint-text'], 'text-align-center'])}
              dangerouslySetInnerHTML={{ __html: footerContent.disclaimer }}
            />

            <Spacer size={3} />

            <FlexContainer
              className={ClassNames([
                styles['footer-bottom'],
                'text-align-center align-items-center justify-content-center'
              ])}
            >
              <div className={ClassNames([styles['copyright'], 'mt-3'])}>
                Copyright &copy; {new Date().getFullYear()} First Business Financial Services, Inc.
                All Rights Reserved.
              </div>
              <div className={ClassNames([styles['links-list']])}>
                <ul
                  className={ClassNames([
                    styles['links'],
                    'display-flex display-block-sm ml-1 mr-2 mb-0 mt-3 sm-text-align-center'
                  ])}
                >
                  {footerContent.links?.map((link, idx) => {
                    const page = (link.page && link.page[0]) || {}
                    const title = link.linkTitle || page.name
                    const external = link.alternateLink ? true : null
                    const destination = link.alternateLink || `/${page.url}`
                    return (
                      <li key={nanoid()} className="mx-1 sm-display-none">
                        <Link
                          className={ClassNames([styles['small']])}
                          href={destination}
                          external={external}
                        >
                          {title}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className={ClassNames([styles['fdic'], 'mt-2'])}>
                <Image
                  src={PickFirst(footerContent.logo1)?.fileName}
                  alt="member fdic"
                  width="101"
                  height="37"
                />
              </div>
            </FlexContainer>
          </div>
        </Container>
        <noscript
          dangerouslySetInnerHTML={{
          __html: `<img src=https://ws.zoominfo.com/pixel/61a78c25f46f5a00191a9481 width="1" height="1" style="display: none;" />`
          }}
        />

        {/* TTD pixel */}
        <script
          type="text/javascript"
          src="https://js.adsrvr.org/up_loader.1.1.0.js"
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `ttd_dom_ready( function() { if (typeof TTDUniversalPixelApi === 'function') { var universalPixelApi = new TTDUniversalPixelApi(); universalPixelApi.init("av77nth", ["52wzyv3"], "https://insight.adsrvr.org/track/up"); } });`
          }}
        />
        {/* TTD pixel */}

      </footer>
      <MarketoForm
        formId={formId}
        isLoaded={formLoaded}
        onRemoveForm={onRemoveForm}
        success={formSuccess}
        successMessage="Thank you for signing up."
        thankYouPage={footerContent.thankYouPage}
      />
    </>
  )
}

export default Footer
